import React from 'react';
export const menuData_ClinicalPM = [
    {
        name: "Home",
        level: "1",
        url: "/Dashboard",
        children: [
            {
                name: "Image Services",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "Dashboard",
                        level: "3",
                        url: "/Dashboard"
                    },
                    {
                        name: "Closed Tasks",
                        level: "3",
                        url: "/ClosedTaskDashboard"
                    },
                    {
                        name: "CR Current Tasks",
                        level: "3",
                        url: "/CRCurrentTask"
                    }                
                ]
            },
            {
                name: "DM Dashboard",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "Global Dashboard",
                        level: "3",
                        url: "/DMGlobalDashboard"
                    },
                    {
                        name: "Study Dashboard",
                        level: "3",
                        url: "/DMStudyDashboard"
                    },
                    {
                        name: "Dashboard Viewer",
                        level: "3",
                        url: "/DMDashboardViewer"
                    },
                    {
                        name: "Documents",
                        level: "3",
                        url: "/DocumentMgmtList"
                    }
                ]
            },
            {
                name: "Central Reader Management",
                level: "2",
                url: "/",
                children: [
                    {
                        name: "CR Site Affiliation",
                        level: "3",
                        url: "/CRSiteAffiliation"
                    },
                    {
                        name: "Affiliation Report",
                        level: "3",
                        url: "/CRSiteAffiliationRpt"
                    }
                ]
            },
            {
                name: "Report",
                level: "2",
                url: "",
                children: [
                    {
                        name: "Global Report Viewer",
                        level: "3",
                        url: "/GlobalReportViewer"
                    }
                ]
            }
        ]
    },
    {
        name: "Help",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "Contact Support",
        level: "1",
        url: "/",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    },
    {
        name: "About",
        level: "1",
        url: "/AboutUs",
        children: [
            {
                name: " ",
                level: "2",
                url: ""
            }]
    }
]