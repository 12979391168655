import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import SelectList from '../UI/Control/SelectList';

const DMStudyDashboard = (props) => {


    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [studyList, setStudyList] = useState([]);
    const [studyID, setStudyID] = useState('');
    const [sponsorList, setSponsorList] = useState([]);
    const [sponsorID, setSponsorID] = useState('');

    const columns = [
        {
            name: "Modality",
            selector: row => row.Modality,
            cellExport: row => row.Modality,
            sortable: true,
            center: true,
            sortFunction: (a, b) => { return props.sortFunctionByColumn(a.Modality, b.Modality); }
        },
        {
            name: "# Sites",
            selector: row => row.Sites,
            cellExport: row => row.Sites,
            sortable: true,
            center: true
        },
        {
            name: "# Subjects",
            selector: row => row.Subjects,
            cellExport: row => row.Subjects,
            sortable: true,
            center: true
        },
        {
            name: "New/QC Complete",
            selector: row => row["New_QC_Complete"],
            cellExport: row => row["New_QC_Complete"],
            sortable: true,
            center: true
        },
        {
            name: "Read Assigned",
            selector: row => row.ReadAssigned,
            cellExport: row => row.ReadAssigned,
            sortable: true,
            center: true
        },
        {
            name: "Adj Required",
            selector: row => row.AdjRequired,
            cellExport: row => row.AdjRequired,
            sortable: true,
            center: true
        },
        {
            name: "Closed",
            selector: row => row.Closed,
            cellExport: row => row.Closed,
            sortable: true,
            center: true
        },
        {
            name: "# Local Reads",
            selector: row => row.LocalReads,
            cellExport: row => row.LocalReads,
            sortable: true,
            center: true
        },       
        {
            name: "Open",
            selector: row => row.OpenQueries,
            cellExport: row => row.OpenQueries,
            sortable: true,
            center: true
        },
        {
            name: "Responded",
            selector: row => row.RespondedQueries,
            cellExport: row => row.RespondedQueries,
            sortable: true,
            center: true
        },       
        {
            name: "Closed",
            selector: row => row.ClosedQueries,
            cellExport: row => row.ClosedQueries,
            sortable: true,
            center: true
        }
    ];

    const filteredColumns = ['Sites'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
            })
        })
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }
    const fetchDMStudyDashboardData = () => {
        fetch('api/Dashboard/GetDMStudyDashboard?StudyID=' + studyID + '&SponsorID=' + sponsorID)
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    }
    const fetchStudies = async () => {
        fetch('api/Site/GetStudiesByUser')
            .then(response => response.json())
            .then(data => {
                setStudyList(data);              
                fetchSponsors();
                fetchDMStudyDashboardData();
                var selStudyID = document.getElementById("ddlStudies").value;
                if (selStudyID == "-1")
                    document.getElementById("ddlStudies").remove(0);
                selStudyID = document.getElementById("ddlStudies").value;
                setStudyID(selStudyID);               
            });
    }
    const fetchSponsors = async () => {
        fetch('api/Site/GetSponsors')
            .then(response => response.json())
            .then(data => {
                setSponsorList(data);
            });
    }  
    const onStudyChangeHandler = (e) => {
        setInputVal("");
        setStudyID(e.target.value);
    }
    const onSponsorChangeHandler = (e) => {
        setInputVal("");
        setSponsorID(e.target.value);
    }
    useEffect(() => {
        fetchStudies();
    }, [studyID, sponsorID]);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={2}>
                <SelectList id='ddlStudies' dataSource={studyList} onChangeHandler={onStudyChangeHandler} style={{ width: "300px" }} />
            </Col>
            <Col md={1} style={{ width: '70px' }}></Col>
            <Col md={2}>
                <SelectList id='ddlSponsors' dataSource={sponsorList} onChangeHandler={onSponsorChangeHandler} style={{ width: "300px" }} />
            </Col>
            <Col md={3}></Col>
            <Col>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
        </Row>
        <div className="tableContainer">
            <table style={{ backgroundColor: '#CCDDEE', color: '#2f3132', fontSize: '12pt', fontWeight: 'bold', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'silver' }}>
                <tr>                    
                    <td colSpan="3" style={{ width: '27.5%', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'silver' }}></td>
                    <td colSpan="3" style={{ width: '36%', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'silver' }}>Task Stage</td>
                    <td colSpan="3" style={{ width: '9.5%', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'silver' }}></td>
                    <td colSpan="3" style={{ width: '29%', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'silver' }}>#Queries</td>
                </tr>
            </table>
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
};

export default DMStudyDashboard;
