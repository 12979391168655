import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import NoData from '../NoData';
import customStyles from '../customStyles';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';
import Card from '../UI/Card/Card';
import SelectList from '../UI/Control/SelectList';
import ToggleSwitch from '../UI/Button/ToggleSwitch';
import MultiSelectList from '../UI/Control/MultiSelectList';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import classes from './../TrialstatNoto.module.css';
import ToggleButton from '../UI/Button/ToggleButton';
import * as ReactDOM from 'react-dom';
import InputString from '../UI/Control/InputString';


const OutcomeMappingDesigner = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        ClearAll();
        FillOutComeForms();
        setSelQList('');
        setShow(true);
    };

    const navigate = useNavigate();
    const [arrOFormsList, setOFormsList] = useState([]);
    const [arrOViewerList, setOViewerList] = useState([]);
    const [selViewerIds, setSelViewerIds] = useState([]);
    const [arrOFVisitList, setOFVisitList] = useState([]);
    const [selVisitIds, setSelVisitIds] = useState([]);
    const [arrQList, setQList] = useState([]);
    const [selQList, setSelQList] = useState('');
    var arrSelQuestions = new Array();

    useEffect(() => {
        if (selQList != '' && props.ocMappingId > 0) {
            let ocqids = selQList.split(',');
            let tglCheck;
            for (let qi of ocqids) {
                tglCheck = document.getElementById("tglChk" + qi);
                if (tglCheck != null) {
                    tglCheck.checked = true;
                }
            }
        }
    }, [selQList])

    const GetSetMappingData = () => {
        
        if (props.ocMappingId > 0) {
            fetch('api/OutcomeNotification/GetOutcomeMappingDetails?OcmId=' + props.ocMappingId)
                .then(response => response.json())
                .then(data => {
                    
                    let fid = data.FormID;
                    document.getElementById('ddlOForms').value = fid;
                    document.getElementById("txtMappingName").value = data.Name;
                    if (fid != "-1") {
                        let vwid = data.ViewerIds;                       
                        let visid = data.VisitIds;

                        FillViewer(fid);
                        let selViewers = vwid.split(',');
                        setSelViewerIds(selViewers);
                       
                        FillQuestions(fid, data.QuesIds);
                        GetVisitsFromViewer(selViewers, fid);
                        let selVisits = visid.split(',');
                        setSelVisitIds(selVisits);
                    }
                });
        }
    };

    const FillOutComeForms = () => {
        fetch('api/OutcomeNotification/GetOutcomeForms?StudyId=' + props.studyId)
            .then(response => response.json())
            .then(data => {
                setOFormsList(data);
                if (props.ocMappingId > 0) {
                    GetSetMappingData();
                   
                }
            });
    };

    const OFormsChangeHandler = (e) => {
        if (e.target.value == '-1' || e.target.value == '') {
            ClearAll();
        }
        else {
            FillViewer(e.target.value);
            FillQuestions(e.target.value, '');
        }
    };

    const ClearAll = () => {
        setOViewerList([]);
        setOFVisitList([]);
        setQList([]);
    };

    const FillViewer = (fid) => {
        setQList([]);
        fetch('api/OutcomeNotification/FillOutcomeViewers?StudyId=' + props.studyId + '&FormId=' + fid)
            .then(response => response.json())
            .then(data => {                
                setOViewerList(data);
            });
    };

    const OViewersChangeHandler = (e) => {
        FillVisits();
    };

    const FillVisits = () => {
        
        var multiSelect = document.getElementById('lstOFViewers');
        var viewerIds = '';
        [...multiSelect].map(option => {
            if (option.selected) {
                viewerIds += viewerIds == '' ? option.value : ',' + option.value;
            }
        });

        setOFVisitList([]);
        GetVisitsFromViewer(viewerIds, document.getElementById("ddlOForms").value);
    };
    const GetVisitsFromViewer = (ViewerIds, fid) => {
        if (ViewerIds != '') {
            fetch('api/OutcomeNotification/GetVisitListFromViewer?StudyId=' + props.studyId + '&FormId=' + fid + '&Sources=' + ViewerIds)
                .then(response => response.json())
                .then(data => {
                    setOFVisitList(data);
                });
        }
    };

    const FillQuestions = (fid, selQList) => {
        fetch('api/OutcomeNotification/FillOutcomeFormQuestions?StudyId=' + props.studyId + '&FormId=' + fid)
            .then(response => response.json())
            .then(data => {               
                setQList([]);
                setQList(data);
                setSelQList(selQList);
            });
    };
    const SaveOCFMapping = () => {
       
        let objDDList = document.getElementById("ddlOForms");
        let objIpValue = objDDList.value;

        let mappName = document.getElementById("txtMappingName");
        objIpValue = mappName.value;
        if (objIpValue == "") {
            alert('Please provide outcome mapping name.');
            mappName.focus();
            return false;
        } 

        if (objIpValue == '-1' || objIpValue == '=') {
            alert('Please select the Form.');
            objDDList.focus();
            return false;
        }             

        let objForm = document.getElementById("ddlOForms");
        let oFormId = objForm.value;
        let formName = objForm.options[objForm.selectedIndex].text;
        let OutMappName = document.getElementById("txtMappingName").value;
        let OutcomeMappingDetailsModel =
        {
            OutMappID: props.ocMappingId,
            Name: OutMappName,
            FormID: oFormId,
            FormName: formName,
            StudyProtocolID: props.studyProtocolId,
            Viewers: new Array(),
            StudyVisits: new Array(),
            Questions: new Array()
        };  

        var multiSelectedFlag = false;
        var multiSelect = null;
        var viewIds = '', visIds = '', quesIds = '';

        //Selected viewers
        multiSelect = document.getElementById('lstOFViewers');
        [...multiSelect].map(option =>
        {
            if (option.selected) {
                OutcomeMappingDetailsModel.Viewers.push({ ViewerID: option.value, Viewer: option.text });
                multiSelectedFlag = true;
                viewIds += viewIds === '' ? option.value : '¢' + option.value;
            }
        });
        if (!multiSelectedFlag) {
            alert('Please select the Viewer(s).');
            multiSelect.focus();
            return false;
        }
        //Selected Study Visits
        multiSelectedFlag = false;
        multiSelect = document.getElementById('lstOFVisits');
        [...multiSelect].map(option => {
            if (option.selected) {
                OutcomeMappingDetailsModel.StudyVisits.push({ VisitID: option.value, VisitCode: option.text });
                multiSelectedFlag = true;
                visIds += visIds === '' ? option.value : '¢' + option.value;
            }
        });
        if (!multiSelectedFlag) {
            alert('Please select the Visits(s).');
            multiSelect.focus();
            return false;
        }

        var quesSelected = false;
        const collection = document.getElementsByClassName("quesToggle");
        for (let i = 0; i < collection.length; i++) {
            if (collection[i].checked) {
                quesSelected = true;
                quesIds += quesIds === '' ? collection[i].getAttribute('name') : '¢' + collection[i].getAttribute('name');
            }
        }
       
        if (!quesSelected) {
            alert('Please select the Question(s).');
            return false;
        }
        if (props.ocMappingId > 0) {
            OutcomeMappingDetailsModel.ViewerIds = viewIds;
            OutcomeMappingDetailsModel.VisitIds = visIds;
            OutcomeMappingDetailsModel.QuesIds = quesIds;
        }
        else {
            OutcomeMappingDetailsModel.Questions = arrSelQuestions;
        }

        fetch('api/OutcomeNotification/SaveOutComeMappings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(OutcomeMappingDetailsModel)
        }).then(r => r.text()).then(res => {

            if (res == 1) {
                setShow(false);
                props.refreshdata();
            }
            else if (res == 2) {
                alert('Outcome Mappings name already exists.');
                return false;
            }
            else if (res == -1) {
                alert('Mappings not added, Something went wrong.');
                return false;
            }
            else if (res == 0) {
                alert('Mappings not added, Mapping is not exists.');
                return false;
            }
            else {
                alert('Mappings not added, Something went wrong.');
                return false;
            }
        })
    };
    
    const onToggleChange = (selRow, e) => {
        var selQuesID = selRow.QuesID;
        if (e.target.checked)
            arrSelQuestions.push({ QuesId: selRow.QuesID, QuesHeader: selRow.QuesHeader });
        else {
            arrSelQuestions = arrSelQuestions.filter(item => item.QuesId !== selQuesID);
        }
    }   
    const columns = [
        {
            name: "QuesID",
            selector: row => row.QuesID,
            cellExport: row => row.QuesID,
            omit: true
        },
        {
            name: "QuesHeader",
            selector: row => row.QuesHeader,
            cellExport: row => row.QuesHeader,
            omit: true
        },
        {
            name: "Question Name with Header",
            selector: row => row.QuesText,
            cellExport: row => row.QuesText,
            sortable: true
        },
        {
            name: "Action",           
            button: true,
            className: 'headercaption',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {<ToggleButton id={"tglChk" + row.QuesID} onToggleChange={() => onToggleChange(row, event)} className="quesToggle" name={row.QuesID} />}
                </div>
            )
        }
    ];

    return (
        <>
            <Button variant="secondary" className="btn btn-md btn-success" style={props.style} onClick={handleShow}> {props.text} </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Twelve} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center", display: "block" }}>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <div className="row mt-2" id="divStudy">
                            <div className="col-sm-6">
                                <label>Mapping Name<MandatoryLabel />:</label>
                                <InputString id="txtMappingName" maxlength="150"/>
                                <br />
                                <label>Forms<MandatoryLabel />:</label>
                                <SelectList id='ddlOForms' dataSource={arrOFormsList} onChangeHandler={OFormsChangeHandler} />
                                <br />
                                <label>Viewer<MandatoryLabel />:</label>
                                <MultiSelectList id="lstOFViewers" dataSource={arrOViewerList} selectedData={selViewerIds} onChangeHandler={OViewersChangeHandler} isMultiSelect="true" size="4" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled} />
                                <br />
                                <label>Visits<MandatoryLabel />:</label>
                                <MultiSelectList id="lstOFVisits" dataSource={arrOFVisitList} selectedData={selVisitIds} isMultiSelect="true" size="4" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled} />
                            </div>
                            <div className="col-sm-6" style={{ marginTop: "25px" }} id="dvTab">
                            <div className="tableContainer" >
                                {arrQList.length > 0 ? <DataTable id="dtQues"
                                    columns={columns}
                                    data={arrQList}
                                    className="table table-striped table-bordered table-hover"
                                    customStyles={customStyles}
                                    striped
                                /> : <NoData headers={columns} />}
                            </div>
                        </div>
                        </div>
                    </Card >
                </Modal.Body>
                <Modal.Footer>
                    {props.action != "view" &&
                        <Button className="btn btn-md btn-success" variant="primary" onClick={SaveOCFMapping}>Save</Button>}
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default OutcomeMappingDesigner;